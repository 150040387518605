import React, { useEffect } from "react"
import { Link } from "gatsby"
import Navbar from "./navbar"

import { rhythm, scale } from "../utils/typography"
// import 'semantic-ui-css/semantic.min.css'
import './layout.module.css'
import { fairyDustCursor } from "./customCursors"

const Layout = ({ location, title, children }) => {
  useEffect(() => {
    new fairyDustCursor();
  }, []);
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <Navbar />
      {/* <header>{header}</header> */}
      <main>{children}</main>
      <footer><i>
        {/* 💰: new posts every tuesday and thursday. if i miss a post and you're the first to point it out, i'll venmo you $50 */}
      </i></footer>
    </div>
  )
}

export default Layout
