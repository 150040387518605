export function ghostCursor(options) {
    let hasWrapperEl = options && options.element
    let element = hasWrapperEl || document.body

    let width = window.innerWidth
    let height = window.innerHeight
    let cursor = { x: width / 2, y: width / 2 }
    let particles = []
    let canvas, context

    let baseImage = new Image()
    baseImage.src =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAATCAYAAACk9eypAAAAAXNSR0IArs4c6QAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAADKADAAQAAAABAAAAEwAAAAAChpcNAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAABqElEQVQoFY3SPUvDQBgH8BREpRHExYiDgmLFl6WC+AYmWeyLg4i7buJX8DMpOujgyxGvUYeCgzhUQUSKKLUS0+ZyptXh8Z5Ti621ekPyJHl+uftfomhaf9Ei5JyxXKfynyEA6EYcLHpwyflT958GAQ7DTABNHd8EbtDbEH2BD5QEQmi2mM8P/Iq+A0SzszEg+3sPjDnDdVEtQKQbMUidHD3xVzf6A9UDEmEm+8h9KTqTVUjT+vB53aHrCbAPiceYq1dQI1Aqv4EhMll0jzv+Y0yiRgCnLRSYyDQHVoqUXe4uKL9l+L7GXC4vkMhE6eW/AOJs9k583ORDUyXMZ8F5SVHVVnllmPNKSFagAJ5DofaqGXw/gHBYg51dIldkmknY3tguv3jOtHR4+MqAzaraJXbEhqHhcQlwGSOi5pytVQHZLN5s0WNe8HPrLYlFsO20RPHkImxsbmHdLJFI76th7Z4SeuF53hTeFLvhRCJRCTKZKxgdnRDbW+iozFJbBMw14/ElwGYc0egMBMFzT21f5Rog33Z7dX02GBm7WV5ZfT5Nn5bE3zuCDe9UxdTpNvK+5AAAAABJRU5ErkJggg=="

    function init() {
        canvas = document.createElement("canvas")
        context = canvas.getContext("2d")
        canvas.style.top = "0px"
        canvas.style.left = "0px"
        canvas.style.pointerEvents = "none"

        if (hasWrapperEl) {
            canvas.style.position = "absolute"
            element.appendChild(canvas)
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
        } else {
            canvas.style.position = "fixed"
            document.body.appendChild(canvas)
            canvas.width = width
            canvas.height = height
        }

        bindEvents()
        loop()
    }

    // Bind events that are needed
    function bindEvents() {
        element.addEventListener("mousemove", onMouseMove)
        element.addEventListener("touchmove", onTouchMove)
        element.addEventListener("touchstart", onTouchMove)
        window.addEventListener("resize", onWindowResize)
    }

    function onWindowResize(e) {
        width = window.innerWidth
        height = window.innerHeight

        if (hasWrapperEl) {
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
        } else {
            canvas.width = width
            canvas.height = height
        }
    }

    function onTouchMove(e) {
        if (e.touches.length > 0) {
            for (let i = 0; i < e.touches.length; i++) {
                addParticle(e.touches[i].clientX, e.touches[i].clientY, baseImage)
            }
        }
    }

    function onMouseMove(e) {
        if (hasWrapperEl) {
            const boundingRect = element.getBoundingClientRect()
            cursor.x = e.clientX - boundingRect.left
            cursor.y = e.clientY - boundingRect.top
        } else {
            cursor.x = e.clientX
            cursor.y = e.clientY
        }

        addParticle(cursor.x, cursor.y, baseImage)
    }

    function addParticle(x, y, image) {
        particles.push(new Particle(x, y, image))
    }

    function updateParticles() {
        context.clearRect(0, 0, width, height)

        // Update
        for (let i = 0; i < particles.length; i++) {
            particles[i].update(context)
        }

        // Remove dead particles
        for (let i = particles.length - 1; i >= 0; i--) {
            if (particles[i].lifeSpan < 0) {
                particles.splice(i, 1)
            }
        }
    }

    function loop() {
        updateParticles()
        requestAnimationFrame(loop)
    }

    /**
     * Particles
     */

    function Particle(x, y, image) {
        const lifeSpan = 40
        this.initialLifeSpan = lifeSpan //ms
        this.lifeSpan = lifeSpan //ms
        this.position = { x: x, y: y }

        this.image = image

        this.update = function (context) {
            this.lifeSpan--
            const opacity = Math.max(this.lifeSpan / this.initialLifeSpan, 0)

            context.globalAlpha = opacity
            context.drawImage(
                this.image,
                this.position.x, // - (this.canv.width / 2) * scale,
                this.position.y //- this.canv.height / 2,
            )
        }
    }

    init()
}

export function fairyDustCursor(options) {
    let possibleColors = (options && options.colors) || [
        "#CFBFF7",
        // "#690000", // maroon
        // "#83858c", // lavender 
        // "#CFBFF7", // grey
    ]
    let hasWrapperEl = options && options.element
    let element = hasWrapperEl || document.body

    let width = window.innerWidth
    let height = window.innerHeight
    const cursor = { x: width / 2, y: width / 2 }
    const lastPos = { x: width / 2, y: width / 2 }
    const particles = []
    const canvImages = []
    let canvas, context

    const char = "*"

    function init() {
        canvas = document.createElement("canvas")
        context = canvas.getContext("2d")
        canvas.style.top = "0px"
        canvas.style.left = "0px"
        canvas.style.pointerEvents = "none"

        if (hasWrapperEl) {
            canvas.style.position = "absolute"
            element.appendChild(canvas)
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
        } else {
            canvas.style.position = "fixed"
            element.appendChild(canvas)
            canvas.width = width
            canvas.height = height
        }

        context.font = "21px serif"
        context.textBaseline = "middle"
        context.textAlign = "center"

        possibleColors.forEach((color) => {
            let measurements = context.measureText(char)
            let bgCanvas = document.createElement("canvas")
            let bgContext = bgCanvas.getContext("2d")

            bgCanvas.width = measurements.width
            bgCanvas.height =
                measurements.actualBoundingBoxAscent +
                measurements.actualBoundingBoxDescent

            bgContext.fillStyle = color
            bgContext.textAlign = "center"
            bgContext.font = "21px serif"
            bgContext.textBaseline = "middle"
            bgContext.fillText(
                char,
                bgCanvas.width / 2,
                measurements.actualBoundingBoxAscent
            )

            canvImages.push(bgCanvas)
        })

        bindEvents()
        loop()
    }

    // Bind events that are needed
    function bindEvents() {
        element.addEventListener("mousemove", onMouseMove)
        element.addEventListener("touchmove", onTouchMove)
        element.addEventListener("touchstart", onTouchMove)
        window.addEventListener("resize", onWindowResize)
    }

    function onWindowResize(e) {
        width = window.innerWidth
        height = window.innerHeight

        if (hasWrapperEl) {
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
        } else {
            canvas.width = width
            canvas.height = height
        }
    }

    function onTouchMove(e) {
        if (e.touches.length > 0) {
            for (let i = 0; i < e.touches.length; i++) {
                addParticle(
                    e.touches[i].clientX,
                    e.touches[i].clientY,
                    canvImages[Math.floor(Math.random() * canvImages.length)]
                )
            }
        }
    }

    function onMouseMove(e) {
        window.requestAnimationFrame(() => {
            if (hasWrapperEl) {
                const boundingRect = element.getBoundingClientRect()
                cursor.x = e.clientX - boundingRect.left
                cursor.y = e.clientY - boundingRect.top
            } else {
                cursor.x = e.clientX
                cursor.y = e.clientY
            }

            const distBetweenPoints = Math.hypot(
                cursor.x - lastPos.x,
                cursor.y - lastPos.y
            )

            if (distBetweenPoints > 1.5) {
                addParticle(
                    cursor.x,
                    cursor.y,
                    canvImages[Math.floor(Math.random() * possibleColors.length)]
                )

                lastPos.x = cursor.x
                lastPos.y = cursor.y
            }
        })
    }

    function addParticle(x, y, color) {
        particles.push(new Particle(x, y, color))
    }

    function updateParticles() {
        context.clearRect(0, 0, width, height)

        // Update
        for (let i = 0; i < particles.length; i++) {
            particles[i].update(context)
        }

        // Remove dead particles
        for (let i = particles.length - 1; i >= 0; i--) {
            if (particles[i].lifeSpan < 0) {
                particles.splice(i, 1)
            }
        }
    }

    function loop() {
        updateParticles()
        requestAnimationFrame(loop)
    }

    function Particle(x, y, canvasItem) {
        const lifeSpan = Math.floor(Math.random() * 30 + 60)
        this.initialLifeSpan = lifeSpan //
        this.lifeSpan = lifeSpan //ms
        this.velocity = {
            x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 2),
            y: Math.random() * 0.7 + 0.9,
        }
        this.position = { x: x, y: y }
        this.canv = canvasItem

        this.update = function (context) {
            this.position.x += this.velocity.x
            this.position.y += this.velocity.y
            this.lifeSpan--

            this.velocity.y += 0.02

            const scale = Math.max(this.lifeSpan / this.initialLifeSpan, 0)

            context.drawImage(
                this.canv,
                this.position.x - (this.canv.width / 2) * scale,
                this.position.y - this.canv.height / 2,
                this.canv.width * scale,
                this.canv.height * scale
            )
        }
    }

    init()
}

export function bubbleCursor(options) {
    let hasWrapperEl = options && options.element
    let element = hasWrapperEl || document.body

    let width = window.innerWidth
    let height = window.innerHeight
    let cursor = { x: width / 2, y: width / 2 }
    let particles = []
    let canvas, context

    let canvImages = []

    function init(wrapperEl) {
        canvas = document.createElement("canvas")
        context = canvas.getContext("2d")

        canvas.style.top = "0px"
        canvas.style.left = "0px"
        canvas.style.pointerEvents = "none"

        if (hasWrapperEl) {
            canvas.style.position = "absolute"
            element.appendChild(canvas)
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
        } else {
            canvas.style.position = "fixed"
            document.body.appendChild(canvas)
            canvas.width = width
            canvas.height = height
        }

        bindEvents()
        loop()
    }

    // Bind events that are needed
    function bindEvents() {
        element.addEventListener("mousemove", onMouseMove)
        element.addEventListener("touchmove", onTouchMove)
        element.addEventListener("touchstart", onTouchMove)
        window.addEventListener("resize", onWindowResize)
    }

    function onWindowResize(e) {
        width = window.innerWidth
        height = window.innerHeight

        if (hasWrapperEl) {
            canvas.width = element.clientWidth
            canvas.height = element.clientHeight
        } else {
            canvas.width = width
            canvas.height = height
        }
    }

    function onTouchMove(e) {
        if (e.touches.length > 0) {
            for (let i = 0; i < e.touches.length; i++) {
                addParticle(
                    e.touches[i].clientX,
                    e.touches[i].clientY,
                    canvImages[Math.floor(Math.random() * canvImages.length)]
                )
            }
        }
    }

    function onMouseMove(e) {
        if (hasWrapperEl) {
            const boundingRect = element.getBoundingClientRect()
            cursor.x = e.clientX - boundingRect.left
            cursor.y = e.clientY - boundingRect.top
        } else {
            cursor.x = e.clientX
            cursor.y = e.clientY
        }

        addParticle(cursor.x, cursor.y)
    }

    function addParticle(x, y, img) {
        particles.push(new Particle(x, y, img))
    }

    function updateParticles() {
        context.clearRect(0, 0, width, height)

        // Update
        for (let i = 0; i < particles.length; i++) {
            particles[i].update(context)
        }

        // Remove dead particles
        for (let i = particles.length - 1; i >= 0; i--) {
            if (particles[i].lifeSpan < 0) {
                particles.splice(i, 1)
            }
        }
    }

    function loop() {
        updateParticles()
        requestAnimationFrame(loop)
    }

    function Particle(x, y, canvasItem) {
        const lifeSpan = Math.floor(Math.random() * 60 + 60)
        this.initialLifeSpan = lifeSpan //
        this.lifeSpan = lifeSpan //ms
        this.velocity = {
            x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() / 10),
            y: -0.4 + Math.random() * -1,
        }
        this.position = { x: x, y: y }
        this.canv = canvasItem

        this.baseDimension = 4

        this.update = function (context) {
            this.position.x += this.velocity.x
            this.position.y += this.velocity.y
            this.velocity.x += ((Math.random() < 0.5 ? -1 : 1) * 2) / 75
            this.velocity.y -= Math.random() / 600
            this.lifeSpan--

            const scale =
                0.2 + (this.initialLifeSpan - this.lifeSpan) / this.initialLifeSpan

            context.fillStyle = "#e6f1f7"
            context.strokeStyle = "#3a92c5"
            context.beginPath()
            context.arc(
                this.position.x - (this.baseDimension / 2) * scale,
                this.position.y - this.baseDimension / 2,
                this.baseDimension * scale,
                0,
                2 * Math.PI
            )

            context.stroke()
            context.fill()

            context.closePath()
        }
    }

    init()
}