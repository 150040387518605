import React, { Component } from 'react'
import { Link } from 'gatsby'

import { rhythm } from './../utils/typography'

export class Navbar extends Component {
    render() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 20,
                    // justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        width: rhythm(24),
                        maxWidth: 400,
                        display: 'flex',
                        flexDirection: 'row',
                        // justifyContent: 'space-between',
                    }}
                >
                    <Link style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        marginRight: 25,
                        fontWeight: 'bold'
                    }} to={'/'}>Home</Link>
                    <Link style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        marginRight: 25,
                        fontWeight: 'bold'
                    }} to={'/about'}>About</Link>
                    <Link style={{
                        boxShadow: `none`,
                        textDecoration: `none`,
                        marginRight: 25,
                        fontWeight: 'bold'
                    }} to={'/threads'}>Threads</Link>
                </div>
            </div>
        )
    }
}

export default Navbar
